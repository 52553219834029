import React from 'react'

import { Badge, Paragraph, SmallText } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="SmallText"
    components={[{ component: SmallText }]}
  >
    <Section>
      <Paragraph>
        <Badge>14px, 400</Badge>
      </Paragraph>
      <Playground>
        <SmallText noMargin>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere
          interdum sem. Quisque ligula eros ullamcorper quis, lacinia quis
          facilisis sed sapien. Mauris varius diam vitae arcu. Sed arcu lectus
          auctor vitae, consectetuer et venenatis eget velit.
        </SmallText>
      </Playground>
      <Playground format="html">
        <p className="small mb-0">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere
          interdum sem. Quisque ligula eros ullamcorper quis, lacinia quis
          facilisis sed sapien. Mauris varius diam vitae arcu. Sed arcu lectus
          auctor vitae, consectetuer et venenatis eget velit.
        </p>
      </Playground>
    </Section>
  </Content>
)

export default Page
